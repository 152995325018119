@font-face {
  font-family: "RubikBold";
  src: url("../../assets/fonts/Rubik-Bold.ttf");
}

@font-face {
  font-family: "RubikBlack";
  src: url("../../assets/fonts/Rubik-Black.ttf");
}

@font-face {
  font-family: "RubikLight";
  src: url("../../assets/fonts/Rubik-Light.ttf");
}

@font-face {
  font-family: "RubikMedium";
  src: url("../../assets/fonts/Rubik-Medium.ttf");
}
