// font-size
$fs-title: 2.5rem;
$fs-subtitle: 2rem;
$fs-p1: 1.5rem;
$fs-p2: 1.125rem;
$fs-p3: 1rem;
$fs-p4: 0.75rem;

// color

// transition-time
$t-transition: 0.5s;
