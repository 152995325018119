.component-container {
  @media only screen and (min-width: 1200px) {
    max-width: 1140px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 960px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 720px;
  }
  @media only screen and (max-width: 576px) {
    max-width: 540px;
  }
  margin: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
